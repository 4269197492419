// import React, { Component } from 'react'
import { Component } from 'react'

export default class ContactTrackPath extends Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      const { pathname, hash, search } = window.location
      // const page = pathname + hash
      if (!localStorage.getItem('entry') || /utm_/.test(search)) {
        localStorage.setItem('entry', pathname + search)
      }
    }
  }

  render() {
    return null
  }
}
