import startCase from 'lodash/startCase'

export function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export function interpolate(value, s1, s2, t1, t2, slope) {
  //Default to linear interpolation
  slope = slope || 0.5
  //If the value is out of the source range, floor to min/max target values
  if (value < Math.min(s1, s2)) {
    return Math.min(s1, s2) === s1 ? t1 : t2
  }
  if (value > Math.max(s1, s2)) {
    return Math.max(s1, s2) === s1 ? t1 : t2
  }
  //Reverse the value, to make it correspond to the target range (this is a side-effect of the bezier calculation)
  value = s2 - value
  let C1 = { x: s1, y: t1 } //Start of bezier curve
  let C3 = { x: s2, y: t2 } //End of bezier curve
  let C2 = {
    //Control point
    x: C3.x,
    y: C1.y + Math.abs(slope) * (C3.y - C1.y)
  }
  //Find out how far the value is on the curve
  let percent = value / (C3.x - C1.x)
  return C1.y * b1(percent) + C2.y * b2(percent) + C3.y * b3(percent)
  function b1(t) {
    return t * t
  }
  function b2(t) {
    return 2 * t * (1 - t)
  }
  function b3(t) {
    return (1 - t) * (1 - t)
  }
}

export function placeholder(width, height) {
  return "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http://www.w3.org/2000/svg'%20viewBox%3D'0%200%20" + width + '%20' + height + "'%20%2F%3E"
}

export function url(string) {
  return (
    typeof string === 'string' &&
    `/${string
      .replace(/[^a-zA-Z0-9-\s]/g, '')
      .replace(/[^a-zA-Z0-9-]/g, '-')
      .replace('--', '-')
      .toLowerCase()}/`
  )
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function getAllKeys(obj) {
  var keys = []

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      keys = keys.concat(getAllKeys(obj[key]))
    } else if (isNumeric(key)) {
      keys.push(obj[key])
    } else {
      keys.push(key)
    }
  })
  return keys
}

export function findValue(object, key) {
  // console.log(object, key)
  var value
  Object.keys(object).some(function(k) {
    if (k === key) {
      value = object[k]
      return true
    }
    if (object[k] && typeof object[k] === 'object') {
      value = findValue(object[k], key)
      return value !== undefined
    }
  })
  return value
}

export function pathToAlt(path) {
  const filename = path.replace(/^.*[\\/]/, '').slice(0, -4)
  const altText = startCase(filename)
  return altText
}

export function getHostName(url) {
  var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
  if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2]
  } else {
    return null
  }
}

export function getDomain(url) {
  var hostName = getHostName(url)
  var domain = hostName

  if (hostName !== null) {
    var parts = hostName.split('.').reverse()

    if (parts !== null && parts.length > 1) {
      domain = parts[1] + '.' + parts[0]

      if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
        domain = parts[2] + '.' + domain
      }
    }
  }
  return domain
}

export function parameterize(str) {
  if (!str) return null

  // Replace accented characters with their non-accented equivalents
  const accentsMap = {
    ą: 'a',
    ć: 'c',
    ę: 'e',
    ł: 'l',
    ń: 'n',
    ó: 'o',
    ś: 's',
    ź: 'z',
    ż: 'z',
    Ą: 'A',
    Ć: 'C',
    Ę: 'E',
    Ł: 'L',
    Ń: 'N',
    Ó: 'O',
    Ś: 'S',
    Ź: 'Z',
    Ż: 'Z'
  }
  str = str.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, match => accentsMap[match] || match)

  // Convert the string to lowercase
  str = str.toLowerCase()

  // Replace all non-alphanumeric characters with a dash
  str = str.replace(/[^a-z0-9]+/g, '-')

  // Remove any leading or trailing dashes
  str = str.replace(/^-+|-+$/g, '')

  // Return the parameterized string
  return str
}

export function parseInlineStyle(style) {
  const template = document.createElement('template')
  template.setAttribute('style', style)
  return Object.entries(template.style)
    .filter(([key]) => !/^[0-9]+$/.test(key))
    .filter(([, value]) => Boolean(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

export function getNthOfTypeIndex(element) {
  const tagName = element.tagName
  let index = 1
  let sibling = element

  while ((sibling = sibling.previousElementSibling) !== null) {
    if (sibling.tagName === tagName) {
      index++
    }
  }

  return index
}
