import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export default function Link({ children, href, to, activeClassName = 'active', partiallyActive, ...other }) {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.

  // console.log(href)
  const internal = /^\/(?!\/)/.test(href || to)
  if (!internal) {
    return (
      <a href={href || to} target="_blank" rel="noreferrer" {...other}>
        {children}
      </a>
    )
  }
  return (
    <GatsbyLink to={href || to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
      {children}
    </GatsbyLink>
  )
}
