import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import ReactGA from 'react-ga'

export class TimeTracker extends Component {
  state = {
    activeTime: 0,
    time: 0,
  }

  componentDidMount() {
    this.timer = this.trackTime()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  trackActiveTime = () =>
    this.setState({ activeTime: this.state.activeTime + 0.1 })

  trackTime = () =>
    setTimeout(() => {
      this.setState({ time: this.state.time + 1 }, () => {
        clearTimeout(this.timer)
        this.timer = this.trackTime()
      })
    }, 1000)

  render() {
    const { activeTime = 5, time = 10 } = this.props

    const show = this.state.activeTime >= activeTime && this.state.time >= time

    return (
      <IdleTimer
        element={typeof window !== 'undefined' && window.document}
        onActive={this.trackActiveTime}
        timeout={10}
      >
        {show && this.props.children}
      </IdleTimer>
    )
  }
}

export class GAEvent extends Component {
  componentDidMount() {
    const { value, ...event } = this.props
    ReactGA.event({ ...event, value: parseInt(value) })
  }

  render() {
    return null
  }
}
