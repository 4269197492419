import React from 'react'
import Img from 'gatsby-image'
// import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import { pathToAlt } from './utils'
import last from 'lodash/last'

function Image({ src, alt, width, height, className, wrapper, loading, images, fluid, objectFit, ...rest }) {
  if (!src) {
    return false
  }

  const fileName = src && src.replace(/^.*[\\/]/, '')
  // console.log(fileName, src)
  // const imageNode = svgImages.nodes.find(node => node.relativePath === src) || fluidImages.nodes.find(node => node.fluid.originalName === fileName)
  // const fluid = imageNode && imageNode.fluid
  // const svg = imageNode && imageNode.svg

  const imageNode = images && images.nodes.find(node => node.fluid && node.fluid.originalName === fileName)

  if (!fluid) {
    fluid = imageNode && imageNode.fluid
  }

  // const orientationClass = fluid && fluid.aspectRatio < 1 ? 'portrait' : 'landscape'

  const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children)

  // console.log(src, fileName, fluid, imageNode)

  // if (svg && svg.originalContent) {
  //   return <figure className={className} dangerouslySetInnerHTML={{ __html: svg.originalContent }} />
  // }

  return (
    <ConditionalWrapper condition={wrapper} wrapper={children => <figure className={classNames(className)}>{children}</figure>}>
      {fluid ? (
        <Img
          fluid={fluid}
          alt={alt || pathToAlt(src) || 'Image'}
          className={classNames(!wrapper && className)}
          style={{ maxWidth: objectFit === 'contain' && last(fluid.sizes.split(', ')) }}
          // objectFit={objectFit || 'cover'}
          loading={loading || 'lazy'}
          {...rest}
        />
      ) : (
        <img
          src={src}
          alt={alt || pathToAlt(src) || 'Image'}
          className={classNames(!wrapper && className)}
          width={width}
          height={height}
          loading={loading || 'lazy'}
          {...rest}
        />
      )}
    </ConditionalWrapper>
  )
}

Image.displayName = 'Image'

export default Image

// svgImages: allFile(filter: { internal: { mediaType: { eq: "image/svg+xml" } } }) {
//   nodes {
//     svg {
//       originalContent
//     }
//     relativePath
//   }
// }
