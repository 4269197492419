import React from 'react'
import { Link } from 'gatsby'
import styles from './button.module.sass'
import classNames from 'classnames'
import ArrowIcon from '../../../static/ui/arrow-icon.inline.svg'

export default function Button({ children, href, url, to, arrow, className, customClass }) {
  return (
    <Link className={classNames(className || styles.button, customClass, 'button')} to={href || url || to || '/'}>
      {children}
      {arrow && <ArrowIcon />}
    </Link>
  )
}
